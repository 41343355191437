import React, { useEffect } from 'react';
import Routes from './routes';
import queryString from 'query-string';

// elements
import { CookieConsent, Sidebar, Topbar } from './elements';

// styles
import { AppContainer, ModalPortal, RoutesContainer } from './App-styles';

// services
import { AuthenticationBusiness, UserBusiness } from './business';
import { useAppDispatch, useLoggedInUser, usePromises, useStore } from './hooks';
import { CookiesProvider } from 'react-cookie';

// theme
import { ThemeProvider } from 'styled-components';
import { useLocation } from 'react-router-dom';

const App = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const user = useLoggedInUser();
    const store = useStore();
    const [{ isInitializing }] = usePromises(() => AuthenticationBusiness.load(dispatch, store));

    useEffect(() => {
        if (isInitializing === false) {
            const query = queryString.parse(location.search);
            if (query.locale != null) {
                UserBusiness.updateLocale(query.locale as any, user.isLoggedIn());
            }
        }
    }, [isInitializing]);

    if (isInitializing) {
        return <div></div>;
    }

    return (
        <ThemeProvider theme={store.settings.theme}>
            <CookiesProvider>
                <CookieConsent />
                <AppContainer className="app-container">
                    <Topbar />
                    <Sidebar />
                    <RoutesContainer className="routes-container">
                        <Routes />
                    </RoutesContainer>
                </AppContainer>
                <ModalPortal id="modal-portal" />
            </CookiesProvider>
        </ThemeProvider>
    );
};

export default App;
