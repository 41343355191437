import React from 'react';
import { ServerFormQuestion } from '../../../../../../types';
import {
    Container,
    Title,
    Question,
    List,
    AddQuestion,
    AddQuestionLabel,
    RemoveQuestion,
} from './FormsSectionModalQuestions-styles';
import { useTranslation } from 'react-i18next';
import { DraggableList, InputMode, SelectInput } from '../../../../../../components';
import { AiOutlineCloseCircle } from 'react-icons/ai';

type setSelectedQuestionsProps = {
    questions: Array<ServerFormQuestion>;
    selectedQuestions: Array<ServerFormQuestion>;
    setSelectedQuestions: React.Dispatch<React.SetStateAction<ServerFormQuestion[]>>;
};

const FormSectionModalQuestions = ({
    questions,
    selectedQuestions,
    setSelectedQuestions,
}: setSelectedQuestionsProps) => {
    const { t } = useTranslation();

    const handleChange = (data: ServerFormQuestion, index: number) => {
        setSelectedQuestions((prev) =>
            prev.map((prevItem, prevIndex) => (index === prevIndex ? data : prevItem))
        );
    };

    const renderQuestion = (question: ServerFormQuestion, index: number) => {
        const options = questions
            .filter((option) => {
                if (
                    option.id !== question.id &&
                    selectedQuestions.some((selected) => selected.id === option.id)
                ) {
                    return false;
                } else {
                    return true;
                }
            })
            .map((option) => ({
                label: option.name,
                value: option.id,
                data: option,
            }));

        return (
            <Question>
                <SelectInput
                    options={options}
                    value={question.id}
                    onChange={(_, data) => handleChange(data!, index)}
                    mode={InputMode.FORM}
                    isSearchable
                    leadingIcon={
                        <RemoveQuestion
                            isDisabled={index === 0 && selectedQuestions.length === 1}
                            onClick={() => removeQuestion(index)}
                        >
                            <AiOutlineCloseCircle />
                        </RemoveQuestion>
                    }
                />
            </Question>
        );
    };

    const addQuestion = () => {
        setSelectedQuestions((prev) => [...prev, { id: '', name: '' } as ServerFormQuestion]);
    };

    const removeQuestion = (index: number) => {
        if (index === 0 && selectedQuestions.length === 1) {
            return;
        }

        setSelectedQuestions((prev) => prev.filter((_, prevIndex) => prevIndex !== index));
    };

    return (
        <Container>
            <Title>{t('generic.questions_list')}</Title>
            <List>
                <DraggableList
                    list={selectedQuestions}
                    renderListItem={renderQuestion}
                    getItemId={(item) => item.id}
                    onChange={(orderedQuestions) => setSelectedQuestions(orderedQuestions)}
                />

                <AddQuestion>
                    <AddQuestionLabel
                        isDisabled={
                            selectedQuestions[selectedQuestions.length - 1].id.length === 0 ||
                            selectedQuestions.length === questions.length
                        }
                        onClick={addQuestion}
                    >
                        {t('forms.questions.modals.add.title')}
                    </AddQuestionLabel>
                </AddQuestion>
            </List>
        </Container>
    );
};

export default FormSectionModalQuestions;
