import React, { useEffect, useMemo, useState, createContext } from 'react';
import { CaseFile, CaseFolder_L1, CaseFolder_L2 } from '../../../../../../types';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useLocale, useLoggedInUser, useToggle } from '../../../../../../hooks';
import { CaseDocumentsContextType } from '../../CaseDocuments';
import queryString, { ParsedQuery } from 'query-string';
import {
    Accordion,
    AccordionBody,
    AccordionContainer,
    AccordionHeader,
    AccordionHeaderContent,
    AccordionHeaderTitle,
    SecondaryOutlinedButton,
} from '../../../../../../components';
import { formatCaseFolderDescription } from '../../../../../../tools';
import {
    CaseDocumentsFolderContent,
    CaseDocumentsFolderCounters,
    CaseDocumentsFolderHeader,
    CaseDocumentsFolderMultiSelect,
} from './components';
import {
    BatchRemoveFilesModal,
    BatchUpdateFilesModal,
    CombineFilesModal,
    OCRFileModal,
    UpdateFileModal,
    UpdateHiddenFilesModal,
    UpdateValidatedFilesModal,
    UploadFileModal,
} from '../../modals';
import { BiPencil } from 'react-icons/bi';
import { CaseFolderManager } from '../../../../../../managers';
import { useWindowSize } from 'usehooks-ts';

type CaseDocumentsFolderProps = {
    caseFolder: CaseFolderManager;
};

export enum ModalActions {
    COMBINE_FILES = 'combine_files',
    UPLOAD_FILE = 'upload_file',
    REMOVE_FILES = 'remove_files',
    UPDATE_FILE = 'update_file',
    UPDATE_VALIDATED_FILES = 'update_validated_files',
    UPDATE_INVALIDATED_FILES = 'update_invalidated_files',
    UPDATE_HIDDEN_FILES = 'update_hidden_files',
    BATCH_UPDATE_FILES = 'batch_update_files',
    OCR = 'ocr',
}

export enum MultipleSelectMode {
    BY_FOLDERS = 'byFolders',
    BY_FILES = 'byFiles',
}

export type CaseDocumentsFolderContextType = {
    caseFolder: CaseFolderManager;
    initialQuery: ParsedQuery<string>;
    selectedItemsCount: number;
    selectedFolders: Array<CaseFolder_L2>;
    selectFolderHandler: (selectedFolder: CaseFolder_L1 | CaseFolder_L2, isChecked: boolean) => void;
    selectedFiles: Array<CaseFile>;
    selectFileHandler: (selectedFile: CaseFile, isChecked: boolean) => void;
    multipleSelectMode: MultipleSelectMode | null;
    setMultipleSelectMode: React.Dispatch<React.SetStateAction<MultipleSelectMode | null>>;
    updateFileUrlHandler: (fileId: string, url: { path: string; expiredAt: Date }) => void;
    openModalHandler: (
        selectedAction: ModalActions,
        payload?: { folders?: Array<CaseFolder_L2>; files?: Array<CaseFile> }
    ) => void;
    closeModalHandler: () => void;
};

export const CaseDocumentsFolderContext = createContext<CaseDocumentsFolderContextType>(null!);

const CaseDocumentsFolder = ({ caseFolder }: CaseDocumentsFolderProps) => {
    const location = useLocation();
    const user = useLoggedInUser();
    const locale = useLocale();
    const [reRender, setReRender] = useState(false);
    const initialQuery = useMemo(() => queryString.parse(location.search), []);
    const windowSize = useWindowSize();

    const { caseFolders, currentCase, selectedOCRFileId } = useOutletContext<CaseDocumentsContextType>();
    const [action, openModal, closeModal] = useToggle<ModalActions>(null);
    const [selectedFolders, setSelectedFolders] = useState<Array<CaseFolder_L2>>([]);
    const [selectedFiles, setSelectedFiles] = useState<Array<CaseFile>>([]);
    const [multipleSelectMode, setMultipleSelectMode] = useState<null | MultipleSelectMode>(null);

    /**
     * Check if should display accordion
     */
    const isDisplayed = useMemo(() => {
        if (reRender === true) return false; // is re-rendering
        if (action === ModalActions.UPDATE_HIDDEN_FILES) return caseFolders.isLoading !== true; // is reloading new files structure
        return true;
    }, [action, caseFolders.isLoading, reRender]);

    /**
     * Selected items count
     */
    const selectedItemsCount = useMemo(
        () =>
            multipleSelectMode === MultipleSelectMode.BY_FOLDERS
                ? selectedFolders.length
                : selectedFiles.length,
        [selectedFolders, selectedFiles, multipleSelectMode]
    );

    useEffect(() => {
        if (multipleSelectMode === MultipleSelectMode.BY_FILES && reRender !== true) {
            // trigger re-render
            setReRender(true);
            setTimeout(() => {
                setReRender(false);
            });
        }
    }, [multipleSelectMode]);

    useEffect(() => {
        if (selectedOCRFileId.length > 0) {
            const caseFile = caseFolder.getCaseFileById(selectedOCRFileId);
            if (caseFile != null) {
                openModalHandler(ModalActions.OCR, { files: [caseFile] });
            }
        }
    }, [selectedOCRFileId]);

    /**
     * Open modal and set selected folders and/or files
     * @param {ModalActions} selectedAction
     * @param {Object} payload
     */
    const openModalHandler = (
        selectedAction: ModalActions,
        payload?: { folders?: Array<CaseFolder_L2>; files?: Array<CaseFile> }
    ) => {
        if (payload?.folders != null) {
            setSelectedFolders(payload.folders);
        }
        if (payload?.files != null) {
            setSelectedFiles(payload.files);
        }
        openModal(selectedAction);
    };

    /**
     * Close modal handler
     * @param {React.MouseEvent} e
     */
    const closeModalHandler = () => {
        setSelectedFolders([]);
        setSelectedFiles([]);
        setMultipleSelectMode(null);
        closeModal();
    };

    /**
     * Select folder handler
     * @param {CaseFolder_L2} selectedFolder
     * @param {boolean} isChecked
     */
    const selectFolderHandler = (selectedFolder: CaseFolder_L1 | CaseFolder_L2, isChecked: boolean) => {
        if ('id' in selectedFolder) {
            // CaseFolder_L2
            setSelectedFolders((prev) =>
                isChecked
                    ? [...prev, selectedFolder]
                    : prev.filter((prevFolder) => prevFolder.id !== selectedFolder.id)
            );
        } else {
            // CaseFolder_L1
            setSelectedFolders((prev) =>
                isChecked
                    ? [...prev, ...selectedFolder.folders]
                    : prev.filter(
                          (prevFolder) =>
                              selectedFolder.folders.some((folder) => folder.id === prevFolder.id) !== true
                      )
            );
        }
    };

    /**
     * Select file handler
     * @param {CaseFile} selectedFile
     * @param {boolean} isChecked
     */
    const selectFileHandler = (selectedFile: CaseFile, isChecked: boolean) => {
        setSelectedFiles((prev) =>
            isChecked ? [...prev, selectedFile] : prev.filter((prevFile) => prevFile.id !== selectedFile.id)
        );
    };

    /**
     * Update filer url
     * @param {string} fileId
     * @param {Object} url
     */
    const updateFileUrlHandler = (fileId: string, url: { path: string; expiredAt: Date }) => {
        caseFolders.update(
            caseFolders.data.map((folder) => ({
                ...folder,
                folders: folder.folders.map((childFolder) => ({
                    ...childFolder,
                    folders: childFolder.folders.map((grandChildFolder) => ({
                        ...grandChildFolder,
                        files: grandChildFolder.files.map((file) => ({
                            ...file,
                            url: file.id === fileId ? url : file.url,
                        })),
                    })),
                })),
                dependents:
                    folder.dependents != null
                        ? folder.dependents.map((dependentFolder) => ({
                              ...dependentFolder,
                              folders: dependentFolder.folders.map((dependentChildFolder) => ({
                                  ...dependentChildFolder,
                                  folders: dependentChildFolder.folders.map((dependentGrandChildFolder) => ({
                                      ...dependentGrandChildFolder,
                                      files: dependentGrandChildFolder.files.map((fileItem) => ({
                                          ...fileItem,
                                          url: fileItem.id === fileId ? url : fileItem.url,
                                      })),
                                  })),
                              })),
                          }))
                        : undefined,
            }))
        );
    };

    return (
        <>
            {action === ModalActions.OCR && (
                <OCRFileModal
                    onClose={closeModalHandler}
                    currentCase={currentCase}
                    currentCaseFolder={caseFolder}
                    selectedFile={selectedFiles[0]!}
                    refresh={caseFolders.refresh}
                />
            )}

            {action === ModalActions.UPLOAD_FILE && (
                <UploadFileModal
                    onClose={closeModalHandler}
                    currentCase={currentCase}
                    currentCaseFolder={caseFolder}
                    selectedFolder={selectedFolders[0]!}
                    refresh={caseFolders.refresh}
                />
            )}
            {action === ModalActions.UPDATE_FILE && (
                <UpdateFileModal
                    onClose={closeModalHandler}
                    currentCase={currentCase}
                    currentCaseFolder={caseFolder}
                    selectedFile={selectedFiles[0]!}
                    refresh={caseFolders.refresh}
                />
            )}
            {action === ModalActions.REMOVE_FILES && (
                <BatchRemoveFilesModal
                    onClose={closeModalHandler}
                    currentCase={currentCase}
                    currentCaseFolder={caseFolder}
                    selectedFiles={selectedFiles}
                    refresh={caseFolders.refresh}
                />
            )}

            {(action === ModalActions.UPDATE_VALIDATED_FILES ||
                action === ModalActions.UPDATE_INVALIDATED_FILES) && (
                <UpdateValidatedFilesModal
                    onClose={closeModalHandler}
                    currentCase={currentCase}
                    currentCaseFolder={caseFolder}
                    selectedFolders={selectedFolders}
                    refresh={caseFolders.refresh}
                    areFoldersValid={action === ModalActions.UPDATE_VALIDATED_FILES}
                />
            )}

            {action === ModalActions.UPDATE_HIDDEN_FILES && (
                <UpdateHiddenFilesModal
                    onClose={closeModalHandler}
                    currentCase={currentCase}
                    currentCaseFolder={caseFolder}
                    refresh={caseFolders.refresh}
                />
            )}

            {action === ModalActions.BATCH_UPDATE_FILES && (
                <BatchUpdateFilesModal
                    onClose={closeModalHandler}
                    currentCase={currentCase}
                    currentCaseFolder={caseFolder}
                    refresh={caseFolders.refresh}
                    caseFolders={caseFolders.data!}
                    selectedFiles={selectedFiles}
                    updateFileUrl={updateFileUrlHandler}
                />
            )}

            {action === ModalActions.COMBINE_FILES && (
                <CombineFilesModal onClose={closeModalHandler} selectedFiles={selectedFiles} />
            )}

            <CaseDocumentsFolderContext.Provider
                value={{
                    caseFolder: caseFolder,
                    initialQuery: initialQuery,
                    multipleSelectMode: multipleSelectMode,
                    selectedItemsCount: selectedItemsCount,
                    selectedFiles: selectedFiles,
                    selectedFolders: selectedFolders,
                    selectFileHandler: selectFileHandler,
                    selectFolderHandler: selectFolderHandler,
                    setMultipleSelectMode: setMultipleSelectMode,
                    updateFileUrlHandler: updateFileUrlHandler,
                    openModalHandler: openModalHandler,
                    closeModalHandler: closeModalHandler,
                }}
            >
                {isDisplayed === true && (
                    <AccordionContainer triggerUpdate={caseFolder}>
                        <Accordion
                            level={0}
                            isToggled={true}
                            isDisabled={!(caseFolder.isDependent === true || caseFolder.dependents != null)}
                        >
                            <AccordionHeader>
                                <AccordionHeaderTitle>
                                    <CaseDocumentsFolderHeader
                                        level={0}
                                        isValidated={caseFolder.isValidated()}
                                        hasErrors={caseFolder.hasErrors()}
                                        hasWarnings={caseFolder.hasWarnings()}
                                        tooltips={caseFolder.getTooltips()}
                                    >
                                        {formatCaseFolderDescription(caseFolder, locale)}
                                    </CaseDocumentsFolderHeader>
                                </AccordionHeaderTitle>
                                <AccordionHeaderContent>
                                    {windowSize.width > 950 && <CaseDocumentsFolderCounters />}
                                    {user.isEmployee() === true && (
                                        <>
                                            <CaseDocumentsFolderMultiSelect />
                                            <SecondaryOutlinedButton
                                                leadingIcon={<BiPencil />}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openModal(ModalActions.UPDATE_HIDDEN_FILES);
                                                }}
                                            />
                                        </>
                                    )}
                                </AccordionHeaderContent>
                            </AccordionHeader>
                            <AccordionBody>
                                <CaseDocumentsFolderContent />
                            </AccordionBody>
                        </Accordion>
                    </AccordionContainer>
                )}
            </CaseDocumentsFolderContext.Provider>
        </>
    );
};

export default CaseDocumentsFolder;
