import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.xl};
`;

export const GoBack = styled(Link)`
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    > svg {
        font-size: ${({ theme }) => theme.font.size.sm};
        color: ${({ theme }) => theme.colors.text.secondary};
    }

    :hover {
        > svg {
            color: ${({ theme }) => theme.colors.primary.main};
        }
    }
`;

export const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary.main} !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: ${({ theme }) => theme.font.size.lg};

    svg {
        font-size: ${({ theme }) => theme.font.size.xl};
        margin-right: ${({ theme }) => theme.box.spacing.sm};
        fill: ${({ theme }) => theme.colors.primary.extended_50} !important;
    }
`;

export const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    margin-left: ${({ theme }) => `calc(${theme.box.spacing.xl} + ${theme.box.spacing.sm})`};
    width: 100%;

    @media (max-width: 700px) {
        margin-left: ${({ theme }) => theme.box.spacing.md};
    }
`;

export const SectionHeaderTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.md};
    width: 50%;

    svg {
        margin-right: ${({ theme }) => theme.box.spacing.md};
    }

    @media (max-width: 700px) {
        width: 70%;
        min-width: max-content;
        margin-right: ${({ theme }) => theme.box.spacing.md};
    }
`;

export const SectionHeaderType = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};

    @media (max-width: 500px) {
        display: none;
    }
`;

export const SectionContainer = styled.div`
    background: ${({ theme }) => theme.colors.onSurface.secondary};
    padding: ${({ theme }) => `${theme.box.spacing.xl} ${theme.box.spacing.md}`};
    display: flex;
    justify-content: center;
`;

export const SectionContent = styled.div`
    background: ${({ theme }) => theme.colors.surface.main};
    padding: ${({ theme }) => `${theme.box.spacing.xl} ${theme.box.spacing.lg}`};
    width: 100%;
    max-width: 80rem;
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.box.shadow.light};
`;
