import React from 'react';
import { Container, Condition, ConditionText, Header } from './FormsListDetailsCondition-styles';
import {
    Actions,
    ServerForm,
    ServerFormCondition,
    ServerFormConditionRequirementType,
    ServerFormQuestionInputType,
} from '../../../../../../../types';
import { useTranslation } from 'react-i18next';
import {
    Accordion,
    AccordionContainer,
    AccordionBody,
    AccordionHeader,
    AccordionHeaderContent,
    AccordionHeaderTitle,
    PrimaryContainedButton,
} from '../../../../../../../components';
import { CustomActions } from '../../../../FormsList';
import moment from 'moment';
import { useLocale } from '../../../../../../../hooks';
import { formatLocalizedText } from '../../../../../../../tools';

type FormsListDetailsConditionsProps = {
    selectedForm: ServerForm;
    openModalHandler: (selectedAction: Actions | CustomActions, form?: ServerForm | undefined) => void;
};

const FormsListDetailsConditions = ({ selectedForm, openModalHandler }: FormsListDetailsConditionsProps) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const renderCondition = (condition: ServerFormCondition, index: number) => {
        const sourceSection = selectedForm.sections.find(
            (section) => section.id === condition.source.section.id
        );
        const sourceQuestion = sourceSection?.questions.find(
            (question) => question.id === condition.source.question.id
        );
        let value = condition.requirement.value;

        switch (sourceQuestion?.inputType) {
            case ServerFormQuestionInputType.DATE_INPUT:
                value = moment(value).format('YYYY-MM-DD');
                break;
            case ServerFormQuestionInputType.SELECT_INPUT:
                const option = sourceQuestion.options.find((item) => item.value === value);
                if (option != null) {
                    value = formatLocalizedText(option.label, locale);
                }
        }

        return (
            <Condition key={index}>
                <ConditionText>
                    {t('generic.if')} &nbsp;
                    <b>
                        [{condition.source.section.name}] [{condition.source.question.name}]
                    </b>
                </ConditionText>
                <ConditionText>
                    {t(
                        `generic.forms_conditions_requirement_type.${condition.requirement.requirementType}` as any
                    )}
                </ConditionText>
                {condition.requirement.requirementType !== ServerFormConditionRequirementType.NOT_EMPTY && (
                    <ConditionText>"{value}"</ConditionText>
                )}
                <ConditionText>
                    {t('generic.then').toLowerCase()}&nbsp;
                    {t(`generic.forms_conditions_action_type.${condition.action.actionType}`)}&nbsp;
                    <b>
                        [{condition.target.section.name}]{' '}
                        {condition.target.question != null && <>[{condition.target.question.name}]</>}
                    </b>
                </ConditionText>
            </Condition>
        );
    };

    return (
        <Container>
            <AccordionContainer triggerUpdate={selectedForm}>
                <Accordion level={0} isToggled isDisabled>
                    <AccordionHeader>
                        <AccordionHeaderTitle>
                            <Header>
                                ({selectedForm.conditions.length}) {t('generic.conditions')}
                            </Header>
                        </AccordionHeaderTitle>
                        <AccordionHeaderContent>
                            <PrimaryContainedButton
                                onClick={() => openModalHandler(CustomActions.UPDATE_CONDITIONS)}
                            >
                                {t('forms.main.modals.conditions.title')}
                            </PrimaryContainedButton>
                        </AccordionHeaderContent>
                    </AccordionHeader>
                    <AccordionBody>
                        {selectedForm.conditions.map((condition, index) => renderCondition(condition, index))}
                    </AccordionBody>
                </Accordion>
            </AccordionContainer>
        </Container>
    );
};

export default FormsListDetailsConditions;
