import React, { useMemo } from 'react';
import { OutletContainer, PageContainer, PageHeader, PageHeaderTitle } from '../../components';
import { Outlet } from 'react-router-dom';
import { BackButton, Container, Content } from './SettingsPages-styles';
import { SettingsSidebar } from './components';
import { useTranslation } from 'react-i18next';
import { HiOutlineCog } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import { Routes } from '../../routes';

const SettingsPages = () => {
    const { t } = useTranslation();

    const location = useLocation();
    const isDisplayingOutlet = useMemo(() => location.pathname.split('/')[2] != null, [location.pathname]);

    return (
        <OutletContainer useBackgroundColor>
            <PageContainer>
                <PageHeader>
                    <PageHeaderTitle>
                        {isDisplayingOutlet ? (
                            <BackButton to={`/${Routes.SETTINGS}`}>
                                <FiChevronLeft />
                                {t('generic.back')}
                            </BackButton>
                        ) : (
                            <>
                                <HiOutlineCog />
                                {t('settings.page_title')}
                            </>
                        )}
                    </PageHeaderTitle>
                </PageHeader>
                <Container isDisplayingOutlet={isDisplayingOutlet}>
                    <SettingsSidebar />
                    <Content>
                        <Outlet />
                    </Content>
                </Container>
            </PageContainer>
        </OutletContainer>
    );
};

export default SettingsPages;
