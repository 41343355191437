import styled from 'styled-components';

export const Container = styled.div`
    > div {
        margin-bottom: ${({ theme }) => theme.box.spacing.md};
    }

    .accordion--0 {
        border-bottom: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    }
`;
