import styled from 'styled-components';

export const Container = styled.div`
    width: 15rem;
    height: 15rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 0 ${({ theme }) => theme.box.spacing.sm};
    background-color: ${({ theme }) => theme.colors.surface.main};
`;
