import styled from 'styled-components';

export const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.surface.main};
    padding: ${({ theme }) => theme.box.spacing.lg};
    border-radius: 8px;
`;

export const DynamicFormFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.box.spacing.md};
`;
