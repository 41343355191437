import React, { useState } from 'react';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormTextInput,
    Modal,
    ModalSize,
} from '../../../../../components';
import { usePromises } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormsModalSections } from '../components';
import { APIService } from '../../../../../services';
import { SelectedSection } from '../types';
import { Locale, ServerForm, ServerFormPayload } from '../../../../../types';

type Props = {
    onClose: () => void;
    refreshFormsList: () => Promise<void>;
    selectedForm: ServerForm;
};

const getInitialSelectedSections = (form: ServerForm) => {
    const initial: Array<Array<SelectedSection>> = [];
    for (const section of form.sections.sort((a, b) => a.index - b.index)) {
        const pageIndex = section.page - 1;

        if (initial[pageIndex] == null) {
            initial[pageIndex] = [];
        }
        initial[pageIndex].push({
            section: section,
            sectionType: section.sectionType,
        });
    }
    return initial;
};

const UpdateFormModal = ({ onClose, refreshFormsList, selectedForm }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, setError }, [sections]] = usePromises(() =>
        APIService.forms().getSectionsList()
    );
    const [selectedSections, setSelectedSections] = useState<Array<Array<SelectedSection>>>(
        getInitialSelectedSections(selectedForm)
    );

    const validationSchema = Yup.object({
        title_en: Yup.string().required(t('generic.error.required_field')),
        title_fr: Yup.string().notRequired(),
    });

    const formik = useFormik({
        initialValues: {
            title_en: selectedForm.title[Locale.EN],
            title_fr: selectedForm.title[Locale.FR],
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            const sanitizedSelectedSections: ServerFormPayload['sections'] = [];

            selectedSections
                .filter((page) => page.length > 0 && page.some((section) => section.section.id !== ''))
                .forEach((page, pageIndex) => {
                    for (const selectedSection of page) {
                        if (selectedSection.section.id === '') {
                            continue;
                        }

                        sanitizedSelectedSections.push({
                            id: selectedSection.section.id,
                            sectionType: selectedSection.sectionType,
                            page: pageIndex + 1,
                            index: sanitizedSelectedSections.length,
                        });
                    }
                });

            if (sanitizedSelectedSections.length === 0) {
                setError(t('forms.main.modals.errors.sections_required'));
                return;
            }

            await executePromise(async () => {
                const payload: ServerFormPayload = {
                    title: {
                        [Locale.EN]: submittedValues.title_en,
                        [Locale.FR]: submittedValues.title_fr ?? '',
                    },
                    sections: sanitizedSelectedSections,
                };
                await APIService.forms().updateForm(selectedForm.id, payload);
                await refreshFormsList();
                onClose();
            });
        },
    });

    return (
        <Modal
            title={t('forms.main.modals.update.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
            isLoading={sections.isLoading}
            size={ModalSize.LARGE}
        >
            <Form formik={formik} validationSchema={validationSchema} oneColumn>
                <FormGroup name="title_en">
                    <FormGroupLabel>{t('generic.title')} EN</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name="title_fr">
                    <FormGroupLabel>{t('generic.title')} FR</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>

                <FormsModalSections
                    selectedSections={selectedSections}
                    setSelectedSections={setSelectedSections}
                    sections={sections.data ?? []}
                />

                <FormFooter
                    submitLabel={t('forms.main.modals.update.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default UpdateFormModal;
