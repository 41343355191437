import React, { useMemo, useRef } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
    List,
    ListItem,
    ListItemContent,
    ListItemDragIcon,
    ListPlaceholder,
} from '../../DraggableList-styles';
import { RxDragHandleHorizontal } from 'react-icons/rx';
import { generateId } from '../../../../tools';
import { DRAGGABLE_LIST_TYPES } from '../../types';

type Props<T> = {
    list: Array<T>;
    listId?: string;
    enableDrag?: boolean;
    renderListItem: (item: T, index: number) => React.ReactNode;
};

const DraggableListDrop = <T,>({ list, listId, renderListItem, enableDrag }: Props<T>) => {
    const droppableId = useRef(listId ?? generateId());

    const isDisabled = useMemo(() => {
        return enableDrag === false ?? list.length <= 1;
    }, [list, enableDrag]);

    return (
        <Droppable droppableId={droppableId.current} type={DRAGGABLE_LIST_TYPES.CHILD}>
            {(provided, snapshot) => (
                <List
                    isDraggingOver={snapshot.isDraggingOver}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="draggable-list"
                >
                    {list.length === 0 && snapshot.isDraggingOver !== true && (
                        <ListPlaceholder>Drop items here</ListPlaceholder>
                    )}

                    {list.map((item, index) => {
                        const id = `${listId}-${index}`;
                        return (
                            <Draggable isDragDisabled={isDisabled} draggableId={id} index={index} key={id}>
                                {(innerProvided, innerSnapshot) => {
                                    return (
                                        <>
                                            <ListItem
                                                ref={innerProvided.innerRef}
                                                {...innerProvided.dragHandleProps}
                                                {...innerProvided.draggableProps}
                                                isDragging={innerSnapshot.isDragging}
                                                style={{
                                                    ...innerProvided.draggableProps.style,
                                                    left: 'auto !important',
                                                    top: 'auto !important',
                                                }}
                                                className="draggable-list__list__item"
                                            >
                                                <ListItemDragIcon
                                                    isDisabled={isDisabled}
                                                    className="draggable-list__list__item__drag-icon"
                                                >
                                                    <RxDragHandleHorizontal />
                                                </ListItemDragIcon>
                                                <ListItemContent>
                                                    {renderListItem(item, index)}
                                                </ListItemContent>
                                            </ListItem>
                                        </>
                                    );
                                }}
                            </Draggable>
                        );
                    })}
                    {provided.placeholder}
                </List>
            )}
        </Droppable>
    );
};

export default DraggableListDrop;
