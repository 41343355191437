import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.1;
`;

export const CheckBoxInputContainer = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.md};

    > label {
        width: 1.6rem;
        height: 1.6rem;
        padding-left: 0;

        > div.checkmark {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
`;

export const SecondaryIconCheck = styled.div`
    width: 1.4rem;
    height: 1.4rem;
    background-color: ${({ theme }) => theme.colors.green.main};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        fill: ${({ theme }) => theme.colors.surface.main} !important;
        font-size: 8px;
    }
    margin-left: ${({ theme }) => theme.box.spacing.md};
`;

export const GoBack = styled(Link)`
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    > svg {
        font-size: ${({ theme }) => theme.font.size.sm};
        color: ${({ theme }) => theme.colors.text.secondary};
    }

    :hover {
        > svg {
            color: ${({ theme }) => theme.colors.primary.main};
        }
    }
`;
