import React, { useMemo } from 'react';
import { Container, Item, ItemChevronIcon, ItemIcon, ItemLabel } from './SettingsSidebar-styles';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../hooks';
import { FaRegUser } from 'react-icons/fa6';
import { Routes } from '../../../../routes';
import { MdOutlineLockPerson } from 'react-icons/md';
import { FaRegSquareCheck } from 'react-icons/fa6';
import { useWindowSize } from 'usehooks-ts';
import { Breakpoints } from '../../../../theme';
import { FiChevronRight } from 'react-icons/fi';

const SettingsSidebar = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const windowSize = useWindowSize();

    const list = useMemo(() => {
        return [
            {
                label: t('settings.my_profile.title'),
                icon: <FaRegUser />,
                path:
                    windowSize.width > Breakpoints.TABLET
                        ? `/${Routes.SETTINGS}`
                        : `/${Routes.SETTINGS}/${Routes.SETTINGS_PROFILE}`,
            },
            {
                label: t('settings.credentials.title'),
                icon: <MdOutlineLockPerson />,
                path: `/${Routes.SETTINGS}/${Routes.SETTINGS_CREDENTIALS}`,
            },
            {
                label: t('settings.preferences.title'),
                icon: <FaRegSquareCheck />,
                path: `/${Routes.SETTINGS}/${Routes.SETTINGS_PREFERENCES}`,
            },
        ];
    }, [locale, windowSize.width]);

    return (
        <Container>
            {list.map(({ label, icon, path }, index) => (
                <Item key={index} to={path} end>
                    <ItemIcon>{icon}</ItemIcon>
                    <ItemLabel>{label}</ItemLabel>

                    <ItemChevronIcon>
                        <FiChevronRight />
                    </ItemChevronIcon>
                </Item>
            ))}
        </Container>
    );
};

export default SettingsSidebar;
