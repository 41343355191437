import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Container } from './PDFPreview-styles';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { usePromises } from '../../hooks';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type Props = {
    fileUrl: string;
};
const PDFPreview = ({ fileUrl }: Props) => {
    const [blob, setBlob] = useState<null | Blob>(null);
    const [{ executePromise, isExecutingPromise }] = usePromises();

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        await executePromise(async () => {
            const response = await fetch(fileUrl, {
                method: 'GET',
            });
            const fileBlob = await response.blob();
            setBlob(fileBlob);
        });
    };

    if (!blob || isExecutingPromise) {
        return <div></div>;
    }

    return (
        <Container>
            <Document file={fileUrl}>
                <Page pageNumber={1} width={150} scale={1} />
            </Document>
        </Container>
    );
};

export default PDFPreview;
