import React, { useMemo } from 'react';
import { CaseForm, ServerForm } from '../../../../../types';
import { DynamicForm, DynamicFormViewMode, Modal, ModalSize } from '../../../../../components';
import { useLocale } from '../../../../../hooks';
import { formatLocalizedText, generateId } from '../../../../../tools';

type Props = {
    onClose: () => void;
    selectedForm: ServerForm;
};

const ReadFormModal = ({ onClose, selectedForm }: Props) => {
    const locale = useLocale();

    const formattedForm = useMemo(() => {
        const form: CaseForm['form'] = {
            id: selectedForm.id,
            title: selectedForm.title,
            conditions: selectedForm.conditions,
            sections: selectedForm.sections.map((section, sectionIndex) => ({
                ...section,
                index: sectionIndex,
                internalId: generateId(),
                isDisplayed: true,
                questions: section.questions.map((question) => ({
                    ...question,
                    isDisplayed: true,
                    answer: { value: '' },
                })),
            })),
        };
        return form;
    }, [selectedForm]);

    return (
        <Modal
            size={ModalSize.LARGE}
            title={formatLocalizedText(selectedForm.title, locale)}
            onClose={onClose}
        >
            <DynamicForm
                form={formattedForm}
                viewMode={DynamicFormViewMode.FILL}
                onSubmit={() => Promise.resolve(onClose())}
                onClose={onClose}
            />
        </Modal>
    );
};

export default ReadFormModal;
