import React, { useEffect, useState } from 'react';
import {
    LocalizedText,
    PageContainer,
    PageHeader,
    PageHeaderActions,
    PageHeaderActionsLeft,
    PageHeaderActionsRight,
    PageHeaderActionsRightButtons,
    PageHeaderTitle,
    PrimaryContainedAddButton,
    PrimaryContainedButton,
    SecondaryOutlinedButton,
    SelectInput,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { LuNewspaper } from 'react-icons/lu';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { useLocale, usePromises, useToggle } from '../../../hooks';
import { Actions, ServerForm } from '../../../types';
import {
    CreateFormModal,
    DeleteFormModal,
    ReadFormModal,
    UpdateFormConditionModal,
    UpdateFormModal,
} from './modals';
import { APIService } from '../../../services';
import { Routes } from '../../../routes';
import { Header, HeaderSelect, HeaderTitle } from './FormsList-styles';
import { FiChevronRight } from 'react-icons/fi';
import { formatLocalizedText } from '../../../tools';
import { BsPencil } from 'react-icons/bs';
import { FaList } from 'react-icons/fa';
import { AiOutlineEye } from 'react-icons/ai';
import { useWindowSize } from 'usehooks-ts';

export type FormsListContext = {
    forms: Array<ServerForm>;
    isLoading: boolean;
    selectedForm: ServerForm | null;
    refreshFormsList: () => Promise<void>;
    openModalHandler: (selectedAction: Actions | CustomActions, form?: ServerForm) => void;
};

export enum CustomActions {
    UPDATE_CONDITIONS = 'update_conditions',
}

const FormsList = () => {
    const params = useParams();
    const navigate = useNavigate();
    const locale = useLocale();
    const { t } = useTranslation();
    const [action, openModal, closeModal] = useToggle<null | Actions | CustomActions>(null);
    const [promises, [forms]] = usePromises(() => APIService.forms().getFormsList());
    const [selectedForm, setSelectedForm] = useState<null | ServerForm>(null);
    const windowSize = useWindowSize();

    useEffect(() => {
        checkSelectedForm();
    }, [params?.form_id, forms.data]);

    const checkSelectedForm = () => {
        if (forms.data == null) {
            return;
        }

        if (params == null || params.form_id == null) {
            // reset form
            setSelectedForm(null);
            return;
        }

        const currentForm = forms.data.find((form) => form.id === params.form_id);
        if (currentForm == null) {
            navigate(`/${Routes.FORMS}`);
            return;
        }

        setSelectedForm(null);
        setTimeout(() => {
            setSelectedForm(currentForm);
        });
    };

    const openModalHandler = (selectedAction: Actions | CustomActions, form?: ServerForm) => {
        openModal(selectedAction);
        if (form != null) {
            setSelectedForm(form);
        }
    };

    return (
        <>
            {action === Actions.READ && <ReadFormModal onClose={closeModal} selectedForm={selectedForm!} />}

            {action === Actions.CREATE && (
                <CreateFormModal onClose={closeModal} refreshFormsList={forms.refresh} />
            )}

            {action === Actions.UPDATE && (
                <UpdateFormModal
                    onClose={closeModal}
                    refreshFormsList={forms.refresh}
                    selectedForm={selectedForm!}
                />
            )}

            {action === CustomActions.UPDATE_CONDITIONS && (
                <UpdateFormConditionModal
                    onClose={closeModal}
                    refreshFormsList={forms.refresh}
                    selectedForm={selectedForm!}
                />
            )}

            {action === Actions.DELETE && (
                <DeleteFormModal
                    onClose={closeModal}
                    refreshFormsList={forms.refresh}
                    selectedForm={selectedForm!}
                />
            )}
            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader display="row">
                    <PageHeaderTitle>
                        <Header>
                            <HeaderTitle to={`/${Routes.FORMS}`}>
                                <LuNewspaper />
                                {selectedForm != null ? t('forms.main.all_form') : t('forms.main.page_title')}
                            </HeaderTitle>
                            {selectedForm != null && (
                                <HeaderSelect>
                                    <FiChevronRight />
                                    <SelectInput
                                        value={selectedForm.id}
                                        options={forms.data.map((form) => ({
                                            label: formatLocalizedText(form.title, locale),
                                            value: form.id,
                                            data: form,
                                        }))}
                                        onChange={() => ({})}
                                        renderOptionLabel={(value, data) => (
                                            <Link to={`/${Routes.FORMS}/${value}`}>
                                                <LocalizedText text={data!.title} />
                                            </Link>
                                        )}
                                    />
                                </HeaderSelect>
                            )}
                        </Header>
                    </PageHeaderTitle>
                    <PageHeaderActions>
                        <PageHeaderActionsLeft>
                            <React.Fragment />
                        </PageHeaderActionsLeft>
                        <PageHeaderActionsRight>
                            <PageHeaderActionsRightButtons>
                                {selectedForm == null ? (
                                    <>
                                        <PrimaryContainedAddButton
                                            onClick={() => openModalHandler(Actions.CREATE)}
                                        >
                                            {windowSize.width > 450 ? t('forms.main.modals.add.title') : ''}
                                        </PrimaryContainedAddButton>
                                    </>
                                ) : (
                                    <>
                                        <SecondaryOutlinedButton
                                            leadingIcon={<FaList />}
                                            listRightSide
                                            listHideToggleIcon
                                            list={[
                                                {
                                                    label: t('forms.main.modals.update.title'),
                                                    onClick: () =>
                                                        openModalHandler(Actions.UPDATE, selectedForm),
                                                },
                                                {
                                                    label: t('forms.main.modals.conditions.title'),
                                                    onClick: () =>
                                                        openModalHandler(
                                                            CustomActions.UPDATE_CONDITIONS,
                                                            selectedForm
                                                        ),
                                                },
                                                {
                                                    label: t('forms.main.modals.preview.title'),
                                                    onClick: () =>
                                                        openModalHandler(Actions.READ, selectedForm),
                                                },
                                                {
                                                    label: t('forms.main.modals.delete.title'),
                                                    onClick: () =>
                                                        openModalHandler(Actions.DELETE, selectedForm),
                                                },
                                            ]}
                                        />
                                        {windowSize.width > 450 && (
                                            <>
                                                <SecondaryOutlinedButton
                                                    leadingIcon={<AiOutlineEye />}
                                                    onClick={() =>
                                                        openModalHandler(Actions.READ, selectedForm)
                                                    }
                                                />
                                                <PrimaryContainedButton
                                                    leadingIcon={<BsPencil />}
                                                    onClick={() =>
                                                        openModalHandler(Actions.UPDATE, selectedForm)
                                                    }
                                                >
                                                    {t('forms.main.modals.update.title')}
                                                </PrimaryContainedButton>
                                            </>
                                        )}
                                    </>
                                )}
                            </PageHeaderActionsRightButtons>
                        </PageHeaderActionsRight>
                    </PageHeaderActions>
                </PageHeader>
                <Outlet
                    context={{
                        forms: forms.data ?? [],
                        isLoading: forms.isLoading,
                        refreshFormsList: forms.refresh,
                        selectedForm: selectedForm,
                        openModalHandler: openModalHandler,
                    }}
                />
            </PageContainer>
        </>
    );
};

export default FormsList;
