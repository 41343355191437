import React from 'react';
import { ServerFormSection, ServerFormSectionType } from '../../../../../../types';
import {
    Container,
    Title,
    Section,
    List,
    AddSection,
    AddSectionLabel,
    RemoveSection,
    Page,
    PageTitle,
    AddPage,
} from './FormsModalSections-styles';
import { useTranslation } from 'react-i18next';
import { DraggableListMultiple, InputMode, SelectInput } from '../../../../../../components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { INITIAL_FORM_SECTION, SelectedSection } from '../../types';
import { useLocale } from '../../../../../../hooks';
import { formatLocalizedText } from '../../../../../../tools';
import { FaPlus } from 'react-icons/fa';

type FormModalSectionsProps = {
    sections: Array<ServerFormSection>;
    selectedSections: Array<Array<SelectedSection>>;
    setSelectedSections: React.Dispatch<React.SetStateAction<Array<Array<SelectedSection>>>>;
};

const FormModalSections = ({ sections, selectedSections, setSelectedSections }: FormModalSectionsProps) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const handleChange = (pageIndex: number, sectionIndex: number, data: SelectedSection) => {
        setSelectedSections((prev) => {
            const updatedPrev = structuredClone(prev);
            updatedPrev[pageIndex][sectionIndex] = data;
            return updatedPrev;
        });
    };

    const addPage = () => {
        setSelectedSections((prev) => [...prev, [INITIAL_FORM_SECTION]]);
    };

    const addSection = (pageIndex: number) => {
        setSelectedSections((prev) => {
            const updatedPrev = structuredClone(prev);
            updatedPrev[pageIndex].push(INITIAL_FORM_SECTION);
            return updatedPrev;
        });
    };

    const removeSection = (pageIndex: number, sectionIndex: number) => {
        setSelectedSections((prev) =>
            prev.map((prevItem, prevIndex) =>
                prevIndex !== pageIndex
                    ? prevItem
                    : prevItem.filter((_, prevItemIndex) => prevItemIndex !== sectionIndex)
            )
        );
    };

    const renderPage = (pageIndex: number, children: React.ReactNode) => {
        return (
            <Page key={pageIndex}>
                <PageTitle>Page {pageIndex + 1}</PageTitle>
                {children}

                <AddSection>
                    <AddSectionLabel isDisabled={false} onClick={() => addSection(pageIndex)}>
                        {t('forms.sections.modals.add.title')}
                    </AddSectionLabel>
                </AddSection>
            </Page>
        );
    };

    const renderSection = (renderedSection: SelectedSection, sectionIndex: number, pageIndex: number) => {
        const sectionsList = sections
            .filter((section) => {
                if (
                    section.id !== renderedSection.section.id &&
                    selectedSections.flat().some((selected) => selected.section.id === section.id)
                ) {
                    return false;
                } else {
                    return true;
                }
            })
            .map((section) => ({
                label: `[${section.name}] ${formatLocalizedText(section.title, locale)}`,
                value: section.id,
                data: section,
            }));

        return (
            <Section key={sectionIndex}>
                <SelectInput
                    options={sectionsList}
                    value={renderedSection.section.id}
                    onChange={(_, data) =>
                        handleChange(pageIndex, sectionIndex, { ...renderedSection, section: data! })
                    }
                    mode={InputMode.FORM}
                    leadingIcon={
                        <RemoveSection
                            isDisabled={
                                pageIndex === 0 &&
                                selectedSections.length <= 1 &&
                                selectedSections[pageIndex].length <= 1
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                removeSection(pageIndex, sectionIndex);
                            }}
                        >
                            <AiOutlineCloseCircle />
                        </RemoveSection>
                    }
                />
                <SelectInput
                    options={[
                        {
                            label: t('generic.form_sections_type.unique'),
                            value: ServerFormSectionType.UNIQUE,
                        },
                        {
                            label: t('generic.form_sections_type.multiple'),
                            value: ServerFormSectionType.MULTIPLE,
                        },
                    ]}
                    value={renderedSection.sectionType}
                    mode={InputMode.FORM}
                    onChange={(value) =>
                        handleChange(sectionIndex, pageIndex, { ...renderedSection, sectionType: value })
                    }
                />
            </Section>
        );
    };

    return (
        <Container>
            <Title>{t('generic.form_sections_list')}</Title>
            <List>
                <DraggableListMultiple
                    lists={selectedSections}
                    renderList={renderPage}
                    renderListItem={renderSection}
                    onChange={(orderedGroups) => setSelectedSections(orderedGroups)}
                />
                <AddPage onClick={addPage}>
                    <FaPlus />
                    Add page
                </AddPage>
            </List>
        </Container>
    );
};

export default FormModalSections;
