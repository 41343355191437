import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CaseChildPageTitleLink = styled(Link)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.text.main} !important;
    :hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.primary.extended_50} !important;
    }
`;

export const CaseChildPageTitleSelect = styled.div`
    display: flex;
    align-items: center;
    margin-left: ${({ theme }) => theme.box.spacing.lg};

    @media (max-width: 600px) {
        display: none;
    }

    > svg {
        color: ${({ theme }) => theme.colors.text.secondary};
        font-size: ${({ theme }) => theme.font.size.sm};
    }

    .select-input-content__label {
        border-color: transparent;

        > div:first-child {
            color: ${({ theme }) => theme.colors.text.main} !important;
            margin-right: ${({ theme }) => theme.box.spacing.md};
        }

        :hover {
            border-color: ${({ theme }) => theme.colors.text.tertiary};

            > div:first-child {
                color: ${({ theme }) => theme.colors.primary.extended_50} !important;
            }
        }
    }

    .select-input-content__list-wrapper {
        width: max-content;

        ul {
            width: max-content;

            > li {
                min-width: max-content;
                padding: 0;
                height: 5rem;
                display: flex;

                a {
                    color: ${({ theme }) => theme.colors.text.main} !important;
                    font-weight: ${({ theme }) => theme.font.weight.normal};
                    height: 100%;
                    width: 100%;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    padding: ${({ theme }) => theme.box.spacing.md};
                }

                &:hover {
                    text-decoration: underline;
                    color: ${({ theme }) => theme.colors.primary.extended_50} !important;

                    a {
                        color: ${({ theme }) => theme.colors.primary.extended_50} !important;
                    }
                }
            }
        }
    }
`;

export const EmptyPlaceholder = styled.div`
    width: 100;
    height: 20rem;
    background-color: ${({ theme }) => theme.colors.onSurface.main};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.font.size.md};
    border-radius: 8px;
`;

export const Banners = styled.div`
    > *:not(:last-child) {
        margin-bottom: 0;
    }
`;
