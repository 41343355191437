import React, { useEffect, useMemo, useState } from 'react';
import { Container, DynamicFormFooter } from './DynamicForm-styles';
import DynamicFormSection from './DynamicFormSection';
import { useFormik } from 'formik';
import { Form, FormFooter } from '../Form';
import { useTranslation } from 'react-i18next';
import { formatPayload, getDisplayedSections, getInitialValues } from './helpers';
import { DynamicFormViewMode } from './types';
import { CaseForm, CaseFormSection, ServerFormSectionType } from '../../types';
import { generateId } from '../../tools';
import { ButtonSize, PrimaryContainedButton, SecondaryOutlinedButton } from '../Button';

type DynamicFormProps = {
    viewMode: DynamicFormViewMode;
    form: CaseForm['form'];
    onSubmit?: (
        answers: Array<{
            sectionId: string;
            sectionInternalId: string;
            questions: Array<{ questionId: string; value: string }>;
        }>
    ) => Promise<void>;
    onClose?: () => void;
    onTouchForm?: () => void;
};

const DynamicForm = ({
    viewMode = DynamicFormViewMode.MANAGEMENT,
    form,
    onSubmit,
    onTouchForm,
}: DynamicFormProps) => {
    const { t } = useTranslation();
    const [displayedSections, setDisplayedSections] = useState<Array<CaseFormSection>>([]);
    const [currentForm, setCurrentForm] = useState(form);
    const [currentPage, setCurrentPage] = useState(1);
    const lastPage = useMemo(() => Math.max(...form.sections.map((section) => section.page)), [form]);

    const formik = useFormik({
        initialValues: getInitialValues(form),
        onSubmit: async (submittedValues) => {
            if (typeof onSubmit === 'function') {
                const payload = formatPayload(submittedValues);
                await onSubmit(payload);
            }
        },
    });

    useEffect(() => {
        const sections = getDisplayedSections(currentForm, formik.values);
        setDisplayedSections(sections);
    }, [currentForm, formik.values, currentPage]);

    useEffect(() => {
        if (formik.dirty === true && typeof onTouchForm === 'function') {
            onTouchForm();
        }
    }, [formik.dirty]);

    const addSections = (section: CaseFormSection) => {
        const sectionInternalId = `ADDED_${generateId()}`;
        setCurrentForm((prev) => ({
            ...prev,
            sections: [
                ...prev.sections,
                {
                    ...section,
                    internalId: sectionInternalId,
                    questions: section.questions.map((question) => ({
                        ...question,
                        isDisplayed: true,
                        answer: { value: '' },
                    })),
                },
            ],
        }));
        const newSection = {
            sectionId: section.id,
            sectionInternalId: sectionInternalId,
            questions: section.questions.reduce((acc, cur) => {
                acc[cur.id] = '';
                return acc;
            }, {} as { [key: string]: string }),
        };
        formik.setValues({
            ...formik.values,
            [`section_${sectionInternalId}`]: newSection,
        });
    };

    const removeSection = (section: CaseFormSection) => {
        const isLast =
            currentForm.sections.filter(
                (item) => item.id === section.id && item.internalId !== section.internalId
            ).length === 0;

        if (isLast === true) {
            setCurrentForm((prev) => ({
                ...prev,
                sections: prev.sections.map((prevSection) =>
                    prevSection.internalId === section.internalId
                        ? {
                              ...prevSection,
                              questions: section.questions.map((question) => ({
                                  ...question,
                                  answer: { value: '' },
                              })),
                          }
                        : prevSection
                ),
            }));
            const updatedValues = structuredClone(formik.values);
            updatedValues[`section_${section.internalId}`] = {
                ...updatedValues[`section_${section.internalId}`],
                questions: section.questions.reduce((acc, question) => {
                    acc[question.id] = '';
                    return acc;
                }, {} as { [key: string]: string }),
            };
            formik.setValues(updatedValues);
        } else {
            setCurrentForm((prev) => ({
                ...prev,
                sections: prev.sections.filter(
                    (prevSection) => prevSection.internalId !== section.internalId
                ),
            }));
            const updatedValues = structuredClone(formik.values);
            delete updatedValues[`section_${section.internalId}`];
            formik.setValues(updatedValues);
        }
    };

    const renderSections = () => {
        const sectionsCounterMap = new Map<string, number>();

        return displayedSections.map((section, index) => {
            let sectionMultipleIndex: undefined | number = undefined;
            if (section.sectionType === ServerFormSectionType.MULTIPLE) {
                const counter = sectionsCounterMap.get(section.id) ?? 0;
                sectionMultipleIndex = counter;
                sectionsCounterMap.set(section.id, counter + 1);
            }

            const showSection =
                (viewMode === DynamicFormViewMode.FILL && section.isDisplayed !== false) ||
                viewMode !== DynamicFormViewMode.FILL;

            const isCurrentPage = currentPage === section.page;

            return (
                <React.Fragment key={index}>
                    {isCurrentPage && showSection && (
                        <DynamicFormSection
                            section={section}
                            addSection={() => addSections(section)}
                            removeSection={() => removeSection(section)}
                            sectionMultipleIndex={sectionMultipleIndex}
                            viewMode={viewMode}
                            canSubmit={typeof onSubmit === 'function'}
                        />
                    )}
                </React.Fragment>
            );
        });
    };

    const handlePageChange = (goNext = true) => {
        if (goNext === true) {
            if (currentPage === lastPage) {
                return;
            } else {
                setCurrentPage((prev) => prev + 1);
            }
        } else {
            if (currentPage > 1) {
                setCurrentPage((prev) => prev - 1);
            }
        }
    };

    return (
        <Container>
            <Form formik={formik} oneColumn>
                {renderSections()}
                {viewMode === DynamicFormViewMode.FILL && typeof onSubmit === 'function' && (
                    <>
                        {currentPage === lastPage ? (
                            <FormFooter
                                submitLabel={t('generic.submit')}
                                secondary={{ onClick: () => handlePageChange(false), label: 'Back' }}
                            />
                        ) : (
                            <DynamicFormFooter>
                                {currentPage > 1 && (
                                    <SecondaryOutlinedButton
                                        size={ButtonSize.LARGE}
                                        onClick={() => handlePageChange(false)}
                                        type="button"
                                    >
                                        Back
                                    </SecondaryOutlinedButton>
                                )}
                                <PrimaryContainedButton
                                    size={ButtonSize.LARGE}
                                    onClick={() => handlePageChange(true)}
                                    type="button"
                                >
                                    Next
                                </PrimaryContainedButton>
                            </DynamicFormFooter>
                        )}
                    </>
                )}
            </Form>
        </Container>
    );
};

export default DynamicForm;
