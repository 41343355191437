import React from 'react';
import { DragDropContext, Draggable, DropResult, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { Container } from './DraggableList-styles';
import { Lists, List } from './DraggableListMultipleStyles';
import { DraggableListDrop } from './components';
import { DRAGGABLE_LIST_TYPES } from './types';

type Props<T> = {
    lists: Array<Array<T>>;
    renderList: (index: number, children: React.ReactNode) => React.JSX.Element;
    renderListItem: (item: T, index: number, listIndex: number) => React.ReactNode;
    onChange: (list: Array<Array<T>>) => void;
};

const DraggableListMultiple = <T,>({ lists, renderList, renderListItem, onChange }: Props<T>) => {
    /**
     * On drag end responder
     * @param {DropResult} result
     * @return {void}
     */
    const onDragEndHandler: OnDragEndResponder = ({ destination, source, type }: DropResult): void => {
        if (!destination) {
            return;
        }

        if (type === DRAGGABLE_LIST_TYPES.PARENT) {
            // Handle reordering of the parent lists
            const reorderedLists = Array.from(lists);
            const [movedList] = reorderedLists.splice(source.index, 1);
            reorderedLists.splice(destination.index, 0, movedList);

            onChange(reorderedLists);
        } else if (type === DRAGGABLE_LIST_TYPES.CHILD) {
            // Handle reordering of items within or between child lists
            const sourceListIndex = parseInt(
                source.droppableId.replace(`${DRAGGABLE_LIST_TYPES.CHILD}_`, ''),
                10
            );
            const destinationListIndex = parseInt(
                destination.droppableId.replace(`${DRAGGABLE_LIST_TYPES.CHILD}_`, ''),
                10
            );

            if (sourceListIndex === destinationListIndex) {
                // Reorder within the same list
                const newList = Array.from(lists[sourceListIndex]);
                const [movedItem] = newList.splice(source.index, 1);
                newList.splice(destination.index, 0, movedItem);

                const newLists = Array.from(lists);
                newLists[sourceListIndex] = newList;
                onChange(newLists);
            } else {
                // Move between different lists
                const sourceList = Array.from(lists[sourceListIndex]);
                const destinationList = Array.from(lists[destinationListIndex]);
                const [movedItem] = sourceList.splice(source.index, 1);
                destinationList.splice(destination.index, 0, movedItem);

                const newLists = Array.from(lists);
                newLists[sourceListIndex] = sourceList;
                newLists[destinationListIndex] = destinationList;

                onChange(newLists);
            }
        }
    };

    return (
        <Container>
            <DragDropContext onDragEnd={onDragEndHandler}>
                <Droppable droppableId="MAIN_LIST" type={DRAGGABLE_LIST_TYPES.PARENT} direction="vertical">
                    {(provided, snapshot) => (
                        <Lists
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            {lists.map((list, listIndex) => {
                                const listId = `${DRAGGABLE_LIST_TYPES.CHILD}_${listIndex}`;
                                return (
                                    <Draggable
                                        draggableId={listId}
                                        key={listId}
                                        index={listIndex}
                                        isDragDisabled={lists.length <= 1}
                                    >
                                        {(innerProvided) => (
                                            <List
                                                ref={innerProvided.innerRef}
                                                {...innerProvided.draggableProps}
                                                {...innerProvided.dragHandleProps}
                                                style={{
                                                    ...innerProvided.draggableProps.style,
                                                    left: 'auto !important',
                                                    top: 'auto !important',
                                                }}
                                            >
                                                {renderList(
                                                    listIndex,
                                                    <DraggableListDrop
                                                        enableDrag={lists.length > 1 || list.length > 1}
                                                        key={listIndex}
                                                        list={list}
                                                        listId={listId}
                                                        renderListItem={(...props) =>
                                                            renderListItem(...props, listIndex)
                                                        }
                                                    />
                                                )}
                                            </List>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </Lists>
                    )}
                </Droppable>
            </DragDropContext>
        </Container>
    );
};

export default DraggableListMultiple;
