import React from 'react';
import { Actions, ServerFormQuestion } from '../../../../../types';
import { LocalizedText, Table, TableBodyRowAction, TableColumnConfig } from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { formatLocalizedText } from '../../../../../tools';
import { useLocale, useLoggedInUser, useTable } from '../../../../../hooks';
import { Placeholder } from './FormsQuestionsListTable-styles';

type Props = {
    isLoading: boolean;
    questions: null | Array<ServerFormQuestion>;
    searchTerm: string;
    openModalHandler: (modalAction: Actions, question: ServerFormQuestion) => void;
};

const FormsQuestionsListTable = ({ questions, searchTerm, openModalHandler }: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const user = useLoggedInUser();

    const columns: Array<TableColumnConfig<ServerFormQuestion>> = [
        {
            id: 'name',
            headerTitle: t('generic.name'),
            getValue: ({ name }) => name,
            width: 'minmax(20rem, 35rem)',
            isSortable: true,
            isDefaultSort: true,
            isSearchable: true,
            isExpanded: true,
        },
        {
            id: 'type',
            headerTitle: t('generic.type'),
            getValue: ({ inputType }) => t(`generic.form_inputs_type.${inputType}`),
            width: '200px',
            isSortable: true,
        },
        {
            id: 'label',
            headerTitle: t('generic.label'),
            getValue: ({ label }) => formatLocalizedText(label, locale),
            width: '1fr',
            isSortable: true,
            isSearchable: true,
        },
        {
            id: 'placeholder',
            headerTitle: t('generic.placeholder'),
            getValue: ({ placeholder }) => formatLocalizedText(placeholder, locale),
            getDisplayedValue: ({ placeholder }) => (
                <Placeholder>
                    <LocalizedText text={placeholder} />
                </Placeholder>
            ),
            width: '1fr',
            isSortable: true,
            isSearchable: true,
            isExpanded: true,
        },
        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            getDisplayedValue: (question, { index, total }) => (
                <TableBodyRowAction
                    positionListAtTop={index >= total - 2}
                    actions={[
                        {
                            label: t('forms.questions.modals.update.title'),
                            onClick: () => openModalHandler(Actions.UPDATE, question),
                        },
                        {
                            label: t('forms.questions.modals.delete.title'),
                            onClick: () => openModalHandler(Actions.DELETE, question),
                        },
                    ]}
                />
            ),
            width: '80px',
            isDisplayed: user.hasPermissions('categories.update'),
        },
    ];

    const table = useTable(questions, columns, { searchTerm: searchTerm });

    return (
        <Table
            {...table}
            columns={table.displayedColumns}
            handleClickRow={(question) => openModalHandler(Actions.UPDATE, question)}
        />
    );
};

export default FormsQuestionsListTable;
