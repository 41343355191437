import styled from 'styled-components';

export const Container = styled.div`
    .accordion--0 {
        &:not(:first-child) {
            > .accordion__header {
                padding-left: 4rem;
            }
        }
    }

    .accordion--1 {
        > .accordion__header {
            padding-left: 5.5rem;
            :hover {
                background-color: ${({ theme }) => theme.colors.onSurface.main};
            }
        }
    }

    .accordion--2 {
        > .accordion__header {
            padding-left: 7rem;
        }

        .accordion__header__content {
            display: none;
        }

        &:hover {
            > .accordion__header {
                background-color: ${({ theme }) => theme.colors.onSurface.main};
            }
            .accordion__header__content {
                display: flex;
            }
        }
    }

    @media (max-width: 550px) {
        .accordion--1 {
            > .accordion__header {
                padding-left: 2rem;
            }

            .accordion--2 {
                > .accordion__header {
                    padding-left: 4rem;
                }
            }
        }
    }
`;
