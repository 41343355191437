import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div<{ isDisplayingOutlet: boolean }>`
    position: relative;
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-left: 30rem;
    min-height: 100%;
    background-color: ${({ theme }) => theme.colors.surface.main};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    ${({ theme }) => theme.breakpoints.tablet_portrait} {
        display: flex;
        overflow: hidden;
        width: 100%;

        > div:first-child {
            width: 100%;
            transition: all 0.3s ease-in;
        }

        > div:last-child {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            min-height: 100%;
            height: max-content;
            transform: translateX(100%);
            transition: all 0.3s ease-in;
        }

        ${({ isDisplayingOutlet }) => {
            if (isDisplayingOutlet === true) {
                return {
                    '> div:first-child': {
                        transform: 'translateX(-100%)',
                    },
                    ' > div:last-child': {
                        transform: 'translateX(0px)',
                    },
                };
            }
        }}
    }
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;

    ${({ theme }) => theme.breakpoints.tablet_portrait} {
        padding: ${({ theme }) => `${theme.box.spacing.lg} ${theme.box.spacing.xxl}`};
    }

    ${({ theme }) => theme.breakpoints.mobile} {
        padding: ${({ theme }) => `${theme.box.spacing.md}`};
    }
`;

export const BackButton = styled(Link)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.text.main} !important;
    font-size: ${({ theme }) => theme.font.size.xl};
`;
