import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
    margin-left: ${({ theme }) => theme.box.spacing.lg};
`;

export const Condition = styled.div`
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-left: ${({ theme }) => `calc(${theme.box.spacing.xxl} + ${theme.box.spacing.sm})`};
    font-size: ${({ theme }) => theme.font.size.md};
    border-top: 1px solid ${({ theme }) => theme.colors.text.tertiary};

    @media (max-width: 700px) {
        padding-left: ${({ theme }) => theme.box.spacing.md};
    }
`;

export const ConditionText = styled.span`
    margin-right: ${({ theme }) => theme.box.spacing.md};
    line-height: 1.4;
`;
