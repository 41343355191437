import React, { useMemo } from 'react';
import {
    Title,
    MultipleSection,
    MultipleSectionTitle,
    MultipleSectionRemove,
    TitleText,
    Description,
} from './DynamicFormSection-styles';
import LocalizedText from '../../LocalizedText';
import { DynamicFormViewMode } from '../types';
import DynamicFormQuestion from '../DynamicFormQuestion';
import { CaseFormSection, ServerFormSectionType } from '../../../types';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { SecondaryOutlinedButton } from '../../Button';
import { FaPlus } from 'react-icons/fa6';
import { useLocale } from '../../../hooks';
import { formatLocalizedText } from '../../../tools';
import { TextEditorInput } from '../../Inputs';

type DynamicFormSectionProps = {
    section: CaseFormSection;
    addSection?: () => void;
    removeSection?: () => void;
    sectionMultipleIndex?: number;
    viewMode?: DynamicFormViewMode;
    canSubmit?: boolean;
};

const DynamicFormSection = ({
    section,
    addSection,
    removeSection,
    sectionMultipleIndex,
    viewMode = DynamicFormViewMode.MANAGEMENT,
    canSubmit,
}: DynamicFormSectionProps) => {
    const locale = useLocale();

    const displayedDescription = useMemo(() => {
        return formatLocalizedText(section.description, locale);
    }, [section.description, locale]);

    const displayedQuestions = useMemo(() => {
        return viewMode === DynamicFormViewMode.FILL
            ? section.questions.filter((question) => question.isDisplayed !== false)
            : section.questions;
    }, [section.questions, viewMode]);

    if (section.sectionType === ServerFormSectionType.MULTIPLE) {
        return (
            <>
                {sectionMultipleIndex === 0 && (
                    <Title viewMode={viewMode}>
                        <TitleText>
                            {viewMode === DynamicFormViewMode.MANAGEMENT && <> [{section.name}]&nbsp;</>}
                            <LocalizedText text={section.title} />
                        </TitleText>
                        {typeof addSection === 'function' && (
                            <SecondaryOutlinedButton leadingIcon={<FaPlus />} onClick={addSection} />
                        )}
                    </Title>
                )}

                {displayedDescription !== '' &&
                    displayedDescription !== '<p></p>' &&
                    viewMode === DynamicFormViewMode.FILL && (
                        <Description>
                            <TextEditorInput initialValue={displayedDescription} isDisabled />
                        </Description>
                    )}

                <MultipleSection>
                    <MultipleSectionRemove
                        onClick={typeof removeSection === 'function' ? removeSection : () => {}}
                    >
                        <AiOutlineCloseCircle />
                    </MultipleSectionRemove>

                    <MultipleSectionTitle>#{sectionMultipleIndex! + 1}</MultipleSectionTitle>

                    {displayedQuestions.map((question) => (
                        <DynamicFormQuestion
                            key={question.id}
                            question={question}
                            viewMode={viewMode}
                            sectionInternalId={section.internalId}
                            canSubmit={canSubmit}
                        />
                    ))}
                </MultipleSection>
            </>
        );
    }

    return (
        <>
            <Title viewMode={viewMode}>
                <TitleText>
                    {viewMode === DynamicFormViewMode.MANAGEMENT && <> [{section.name}]&nbsp;</>}
                    <LocalizedText text={section.title} />
                </TitleText>
            </Title>
            {displayedDescription !== '' &&
                displayedDescription !== '<p></p>' &&
                viewMode === DynamicFormViewMode.FILL && (
                    <Description>
                        <TextEditorInput initialValue={displayedDescription} isDisabled />
                    </Description>
                )}

            {displayedQuestions.map((question) => (
                <DynamicFormQuestion
                    key={question.id}
                    question={question}
                    viewMode={viewMode}
                    sectionInternalId={section.internalId}
                    canSubmit={canSubmit}
                />
            ))}
        </>
    );
};

export default DynamicFormSection;
