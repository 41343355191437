import React, { useMemo } from 'react';
import { Container, InputContainer, RemoveCondition, Text } from './UpdateFormConditionModalItem-styles';
import {
    ServerForm,
    ServerFormConditionActionType,
    ServerFormConditionRequirementType,
    ServerFormQuestionInputType,
} from '../../../../../../../types';
import { useTranslation } from 'react-i18next';
import { DateInput, SelectInput, TextInput } from '../../../../../../../components';
import { ConditionItem } from '../../UpdateFormConditionModal';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useLocale } from '../../../../../../../hooks';
import { formatLocalizedText } from '../../../../../../../tools';

type UpdateFormConditionModalItemProps = {
    selectedForm: ServerForm;
    conditionItem: ConditionItem;
    index: number;
    updateCondition: (index: number, condition: ConditionItem) => void;
    removeCondition: (index: number) => void;
};

const UpdateFormConditionModalItem = ({
    selectedForm,
    conditionItem,
    index,
    updateCondition,
    removeCondition,
}: UpdateFormConditionModalItemProps) => {
    const locale = useLocale();
    const { t } = useTranslation();

    const updateConditionHandler = <K extends keyof ConditionItem>(key: K, value: ConditionItem[K]) => {
        const updatedCondition = {
            ...conditionItem,
            [key]: value,
        };

        switch (key) {
            case 'actionType':
                updatedCondition.target = '';
                break;
            case 'source':
                updatedCondition.actionType = '' as ServerFormConditionActionType;
                updatedCondition.target = '';
                updatedCondition.requirementType = '' as ServerFormConditionRequirementType;
                updatedCondition.requirementValue = '';
                break;
            case 'requirementType':
                if (value === ServerFormConditionRequirementType.NOT_EMPTY) {
                    updatedCondition.requirementValue = '__not__empty';
                }
                break;
        }
        updateCondition(index, updatedCondition);
    };

    const selectedSourceQuestion = useMemo(() => {
        if (conditionItem == null || selectedForm == null) {
            return null;
        }
        const [sectionId, questionId] = conditionItem.source.split('_');
        const section = selectedForm.sections.find((item) => item.id === sectionId);
        if (section != null) {
            const question = section.questions.find((item) => item.id === questionId);
            if (question != null) {
                return question;
            }
        }
        return null;
    }, [conditionItem.source, selectedForm]);

    const sourcesList = useMemo(() => {
        const list: Array<{ label: string; value: string }> = [];
        for (const section of selectedForm.sections) {
            for (const question of section.questions) {
                list.push({
                    label: `[${section.name}] [${question.name}]`,
                    value: `${section.id}_${question.id}`,
                });
            }
        }
        return list;
    }, [selectedForm]);

    const conditionsRequirementsList = useMemo(() => {
        const list = [
            {
                label: t('generic.forms_conditions_requirement_type.equals'),
                value: ServerFormConditionRequirementType.EQUAL,
            },
        ];

        if (conditionItem.source === '') {
            return list;
        }

        const inputType = selectedSourceQuestion?.inputType;

        switch (inputType) {
            case ServerFormQuestionInputType.TEXT_INPUT:
                return [
                    ...list,
                    {
                        label: t('generic.forms_conditions_requirement_type.contains'),
                        value: ServerFormConditionRequirementType.CONTAINS,
                    },
                    {
                        label: t('generic.forms_conditions_requirement_type.start_with'),
                        value: ServerFormConditionRequirementType.START_WITH,
                    },
                    {
                        label: t('generic.forms_conditions_requirement_type.end_with'),
                        value: ServerFormConditionRequirementType.END_WITH,
                    },
                    {
                        label: t('generic.forms_conditions_requirement_type.not_empty'),
                        value: ServerFormConditionRequirementType.NOT_EMPTY,
                    },
                ];
            case ServerFormQuestionInputType.NUMBER_INPUT:
                return [
                    ...list,
                    {
                        label: t('generic.forms_conditions_requirement_type.greater_than'),
                        value: ServerFormConditionRequirementType.GREATER_THAN,
                    },
                    {
                        label: t('generic.forms_conditions_requirement_type.lower_than'),
                        value: ServerFormConditionRequirementType.LOWER_THAN,
                    },
                    {
                        label: t('generic.forms_conditions_requirement_type.not_empty'),
                        value: ServerFormConditionRequirementType.NOT_EMPTY,
                    },
                ];
            case ServerFormQuestionInputType.DATE_INPUT:
                return [
                    ...list,
                    {
                        label: t('generic.forms_conditions_requirement_type.before_date'),
                        value: ServerFormConditionRequirementType.BEFORE_DATE,
                    },
                    {
                        label: t('generic.forms_conditions_requirement_type.after_date'),
                        value: ServerFormConditionRequirementType.AFTER_DATE,
                    },
                    {
                        label: t('generic.forms_conditions_requirement_type.not_empty'),
                        value: ServerFormConditionRequirementType.NOT_EMPTY,
                    },
                ];
            case ServerFormQuestionInputType.SELECT_INPUT:
                return [
                    ...list,
                    {
                        label: t('generic.forms_conditions_requirement_type.not_empty'),
                        value: ServerFormConditionRequirementType.NOT_EMPTY,
                    },
                ];
            default: {
                return list;
            }
        }
    }, [selectedSourceQuestion]);

    const conditionsActionsList = useMemo(() => {
        return Object.values(ServerFormConditionActionType).map((action) => ({
            label: t(`generic.forms_conditions_action_type.${action}`).toUpperCase(),
            value: action,
        }));
    }, []);

    const targetsList = useMemo(() => {
        const list: Array<{ label: string; value: string }> = [];

        for (const section of selectedForm.sections) {
            const [conditionSourceSectionId, conditionSourceQuestionId] = conditionItem.source.split('_');

            if (section.id !== conditionSourceSectionId) {
                list.push({
                    label: `[${section.name}]`,
                    value: section.id,
                });
            }

            for (const question of section.questions) {
                const isSource =
                    section.id === conditionSourceSectionId && question.id === conditionSourceQuestionId;
                if (isSource !== true) {
                    list.push({
                        label: `[${section.name}] [${question.name}]`,
                        value: `${section.id}_${question.id}`,
                    });
                }
            }
        }

        return list;
    }, [selectedForm, conditionItem.source]);

    const renderRequirementInputValue = () => {
        if (selectedSourceQuestion == null) {
            return (
                <TextInput
                    value={conditionItem.requirementValue}
                    onChange={(e) => updateConditionHandler('requirementValue', e.target.value)}
                    disabled
                />
            );
        }

        if (conditionItem.requirementType === ServerFormConditionRequirementType.NOT_EMPTY) {
            return <TextInput value="" disabled />;
        }

        switch (selectedSourceQuestion.inputType) {
            case ServerFormQuestionInputType.TEXT_INPUT:
            case ServerFormQuestionInputType.NUMBER_INPUT:
                return (
                    <TextInput
                        value={conditionItem.requirementValue}
                        onChange={(e) => updateConditionHandler('requirementValue', e.target.value)}
                        type={
                            selectedSourceQuestion.inputType === ServerFormQuestionInputType.NUMBER_INPUT
                                ? 'number'
                                : 'text'
                        }
                    />
                );
            case ServerFormQuestionInputType.DATE_INPUT:
                const dateValue = !isNaN(new Date(conditionItem.requirementValue).getTime())
                    ? new Date(conditionItem.requirementValue)
                    : undefined;

                return (
                    <DateInput
                        value={dateValue}
                        onChange={(selected) => updateConditionHandler('requirementValue', selected as any)}
                    />
                );
            case ServerFormQuestionInputType.SELECT_INPUT:
                const list = selectedSourceQuestion.options.map((option) => ({
                    label: formatLocalizedText(option.label, locale),
                    value: option.value,
                }));

                return (
                    <SelectInput
                        value={conditionItem.requirementValue}
                        options={[
                            ...list,
                            {
                                label: t('generic.forms_conditions_requirement_type.not_empty'),
                                value: ServerFormConditionRequirementType.NOT_EMPTY,
                            },
                        ]}
                        onChange={(value) => updateConditionHandler('requirementValue', value)}
                    />
                );
        }
    };

    return (
        <Container>
            <Text>{t('generic.if')}</Text>
            <InputContainer>
                <SelectInput
                    options={sourcesList}
                    value={conditionItem.source}
                    onChange={(value) => updateConditionHandler('source', value)}
                />
            </InputContainer>
            <InputContainer>
                <SelectInput
                    options={conditionsRequirementsList}
                    value={conditionItem.requirementType}
                    onChange={(value) => updateConditionHandler('requirementType', value)}
                />
            </InputContainer>
            <InputContainer>{renderRequirementInputValue()}</InputContainer>
            <Text>{t('generic.then')}</Text>
            <InputContainer>
                <SelectInput
                    options={conditionsActionsList}
                    value={conditionItem.actionType}
                    onChange={(value) => updateConditionHandler('actionType', value)}
                />
            </InputContainer>
            <InputContainer>
                <SelectInput
                    options={targetsList}
                    value={conditionItem.target}
                    onChange={(value) => updateConditionHandler('target', value)}
                />
            </InputContainer>
            <RemoveCondition>
                <AiOutlineCloseCircle onClick={() => removeCondition(index)} />
            </RemoveCondition>
        </Container>
    );
};

export default UpdateFormConditionModalItem;
