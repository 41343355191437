import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Locale,
    ServerFormSection,
    ServerFormSectionPayload,
    ServerFormQuestion,
} from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../../hooks';
import { APIService } from '../../../../../services';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormTextEditorInput,
    FormTextInput,
    Modal,
    ModalSize,
} from '../../../../../components';
import { FormSectionModalQuestions } from '../components';

type Props = {
    onClose: () => void;
    refreshSectionsList: () => Promise<void>;
    sectionsList: Array<ServerFormSection>;
    selectedSection: ServerFormSection;
};

const UpdateFormsSectionModal = ({ onClose, refreshSectionsList, sectionsList, selectedSection }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, setError }, [questions]] = usePromises(() =>
        APIService.forms().getQuestionsList()
    );

    const [selectedQuestions, setSelectedQuestions] = useState<Array<ServerFormQuestion>>(
        selectedSection.questions
    );

    const validationSchema = Yup.object({
        name: Yup.string().required(t('generic.error.required_field')),
        title_en: Yup.string().required(t('generic.error.required_field')),
        title_fr: Yup.string().notRequired(),
        description_en: Yup.string().notRequired(),
        description_fr: Yup.string().notRequired(),
    });

    const formik = useFormik({
        initialValues: {
            name: selectedSection.name,
            title_en: selectedSection.title[Locale.EN],
            title_fr: selectedSection.title[Locale.FR],
            description_en: selectedSection.description[Locale.EN],
            description_fr: selectedSection.description[Locale.FR],
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            if (selectedQuestions.length === 1 && selectedQuestions[0].id === '') {
                setError(t('forms.sections.modals.errors.questions_required'));
                return;
            }

            if (
                sectionsList.some(
                    (sectionsListItem) =>
                        sectionsListItem.name === submittedValues.name &&
                        sectionsListItem.id !== selectedSection.id
                )
            ) {
                setError(
                    t('forms.sections.modals.errors.section_name_exists', {
                        sectionName: submittedValues.name,
                    })
                );
                return;
            }

            await executePromise(async () => {
                const payload: ServerFormSectionPayload = {
                    name: submittedValues.name,
                    title: {
                        [Locale.EN]: submittedValues.title_en,
                        [Locale.FR]: submittedValues.title_fr ?? '',
                    },
                    description: {
                        [Locale.EN]: submittedValues.description_en ?? '',
                        [Locale.FR]: submittedValues.description_fr ?? '',
                    },
                    questions: selectedQuestions
                        .filter((selectedQuestion) => selectedQuestion.id !== '')
                        .map((selectedQuestion, index) => ({
                            id: selectedQuestion.id,
                            index: index,
                        })),
                };

                await APIService.forms().updateSection(selectedSection.id, payload);
                await refreshSectionsList();
                onClose();
            });
        },
    });

    return (
        <Modal
            title={t('forms.sections.modals.update.title')}
            onClose={onClose}
            isLoading={questions.isLoading}
            error={error}
            clearError={clearError}
            size={ModalSize.LARGE}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="name" expand>
                    <FormGroupLabel>{t('generic.name')}</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name="title_en">
                    <FormGroupLabel>{t('generic.title')} EN</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name="title_fr">
                    <FormGroupLabel>{t('generic.title')} FR</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name="description_en">
                    <FormGroupLabel>{t('generic.description')} EN</FormGroupLabel>
                    <FormTextEditorInput />
                </FormGroup>
                <FormGroup name="description_fr">
                    <FormGroupLabel>{t('generic.description')} FR</FormGroupLabel>
                    <FormTextEditorInput />
                </FormGroup>
                <FormSectionModalQuestions
                    questions={questions.data ?? []}
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={setSelectedQuestions}
                />
                <FormFooter
                    submitLabel={t('forms.sections.modals.update.title')}
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default UpdateFormsSectionModal;
