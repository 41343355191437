import styled from 'styled-components';

export const InputContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
    max-width: 50rem;
`;

export const Grid = styled.div`
    position: relative;
    height: 100%;
    min-height: 50vh;

    .react-grid-placeholder {
        border-radius: 8px;

        background-color: ${({ theme }) =>
            theme.colors.primary.extended_50}; /* Change to your desired color */
    }
`;

export const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const GridItemFile = styled.div`
    padding: ${({ theme }) => theme.box.spacing.md};
    border-radius: 8px;
    cursor: grab;
    user-select: none;

    &:active {
        cursor: grabbing;
        background: ${({ theme }) => theme.colors.background.main};
        box-shadow: ${({ theme }) => theme.box.shadow.main};
    }

    box-shadow: ${({ theme }) => theme.box.shadow.main};
    background: ${({ theme }) => theme.colors.background.secondary};
`;

export const GridItemNumber = styled.div`
    margin-top: ${({ theme }) => theme.box.spacing.md};
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const GridItemFileName = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    margin-top: ${({ theme }) => theme.box.spacing.md};
    text-align: center;
    line-height: 1.4;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 90%;
`;
