import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    height: 100%;
`;

export const List = styled.div<{ isDraggingOver: boolean }>`
    position: relative;
    flex-grow: 1;
    background-color: ${({ theme, isDraggingOver }) =>
        isDraggingOver ? theme.colors.primary.extended_10 : 'none'};
`;

export const ListPlaceholder = styled.div`
    padding: ${({ theme }) => theme.box.spacing.md};
    font-size: ${({ theme }) => theme.font.size.md};
    text-align: center;
`;

export const ListItem = styled.div<{ isDragging: boolean }>`
    padding: ${({ theme }) => theme.box.spacing.sm};
    display: flex;
    align-items: center;
    background-color: ${({ theme, isDragging }) => (isDragging ? theme.colors.surface.secondary : 'none')};
    border-radius: 4px;
    position: relative;
`;

export const ListItemDragIcon = styled.div<{ isDisabled: boolean }>`
    margin-right: ${({ theme }) => theme.box.spacing.md};
    margin-left: ${({ theme }) => theme.box.spacing.sm};

    svg {
        font-size: ${({ theme }) => theme.font.size.lg};
        color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.text.disabled : 'black')};
    }
`;

export const ListItemContent = styled.div`
    flex: 1;
`;
