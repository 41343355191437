import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CaseFile } from '../../../../../../types';
import {
    InputMode,
    Modal,
    ModalFooter,
    ModalSize,
    PDFPreview,
    TextInput,
} from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    GridItemFileName,
    GridItem,
    GridItemFile,
    GridItemNumber,
    InputContainer,
} from './CombineFilesModal-styles';
import GridLayout, { Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { usePromises } from '../../../../../../hooks';
import { APIService } from '../../../../../../services';
import { useOutletContext } from 'react-router-dom';
import { CaseDocumentsContextType } from '../../CaseDocuments';
import { PDFDocument } from 'pdf-lib';

type CombineFilesModalProps = {
    onClose: () => void;
    selectedFiles: Array<CaseFile>;
};

const itemWidth = 170; // Width of each item

const CombineFilesModal = ({ onClose, selectedFiles }: CombineFilesModalProps) => {
    const { t } = useTranslation();
    const contentRef = useRef<HTMLDivElement>(null);
    const [fileName, setFileName] = useState('combined');
    const [cols, setCols] = useState(0); // Number of columns
    const [{ executePromise, error, clearError, isInitializing, isExecutingPromise }, [files]] = usePromises(
        () => loadFiles()
    );
    const { currentCase, currentCaseFolder } = useOutletContext<CaseDocumentsContextType>();

    useEffect(() => {
        const updateCols = () => {
            if (contentRef.current) {
                const width = contentRef.current.clientWidth;
                setCols(Math.floor(width / itemWidth));
            }
        };

        updateCols(); // Set initial columns
        window.addEventListener('resize', updateCols); // Update columns on resize

        return () => {
            window.removeEventListener('resize', updateCols); // Cleanup event listener
        };
    }, [contentRef]);

    const initialLayout = useMemo(() => {
        if (files.data == null) {
            return null;
        }
        return files.data.map((file, index) => {
            const x = index % cols; // Column index
            const y = Math.floor(index / cols); // Row number
            return {
                i: file.id, // unique id for each file
                x, // calculated x position
                y, // calculated y position
                w: 1, // width
                h: 1, // height
            };
        });
    }, [files.data]);

    const loadFiles = async () => {
        const loadedFiles = await Promise.all(
            selectedFiles.map(async (selectedFile) => {
                const response = await APIService.cases().getFileUrl(
                    currentCase.id,
                    currentCaseFolder!.id,
                    selectedFile.id
                );
                return {
                    ...selectedFile,
                    url: response,
                } as CaseFile;
            })
        );
        return loadedFiles;
    };

    const handleLayoutChange = (layout: Layout[]) => {
        // Reorder the files array based on the new layout
        const reorderedFiles = layout
            .map((l) => files.data.find((file) => file.id === l.i))
            .filter(Boolean) as CaseFile[];

        // Compact the layout to fill gaps
        const compactedLayout = compactLayout(layout, cols);

        // Set the updated files order to state

        files.update(reorderedFiles);

        return compactedLayout; // Return the new compacted layout
    };

    const compactLayout = (layout: Layout[], currentCols: number) => {
        const newLayout = layout
            .sort((a, b) => a.y - b.y || a.x - b.x) // Sort by y then by x
            .map((item, index) => ({
                ...item,
                x: index % currentCols,
                y: Math.floor(index / currentCols),
            }));

        return newLayout;
    };

    const onClickCombine = async () => {
        await executePromise(async () => {
            const pdfDoc = await PDFDocument.create(); // Create a new PDF document

            for (const file of files.data) {
                const response = await fetch(file.url!.path);
                const pdfBytes = await response.arrayBuffer(); // Fetch the PDF bytes
                const fileDoc = await PDFDocument.load(pdfBytes); // Load the PDF document
                const copiedPages = await pdfDoc.copyPages(fileDoc, fileDoc.getPageIndices()); // Copy pages
                copiedPages.forEach((page) => pdfDoc.addPage(page)); // Add copied pages to the new document
            }
            const combinedPdfBytes = await pdfDoc.save(); // Save the new PDF document

            // Create a blob and download the merged PDF
            const blob = new Blob([combinedPdfBytes], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `${fileName || 'combined'}.pdf`; // You can customize the filename here
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Clean up the URL object
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.documents.modals.combine.title')}
            onClose={onClose}
            size={ModalSize.LARGE}
            error={error}
            clearError={clearError}
        >
            <InputContainer>
                <TextInput
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                    label={t('generic.filename')}
                    trailingIcon=".pdf"
                    mode={InputMode.FORM}
                />
            </InputContainer>
            <Grid ref={contentRef}>
                {isInitializing !== true && (
                    <GridLayout
                        layout={initialLayout!}
                        cols={cols}
                        rowHeight={200}
                        width={contentRef.current!.clientWidth}
                        onLayoutChange={handleLayoutChange}
                        compactType="horizontal" // Use vertical compacting
                        isDraggable={true} // Enable dragging
                        isResizable={false}
                    >
                        {files.data.map((file, index) => (
                            <GridItem key={file.id} className="grid-item">
                                <GridItemFile>
                                    <PDFPreview fileUrl={file.url!.path} />
                                    <GridItemFileName>{file.originalName}</GridItemFileName>
                                </GridItemFile>
                                <GridItemNumber>{index + 1}</GridItemNumber>
                            </GridItem>
                        ))}
                    </GridLayout>
                )}
            </Grid>
            <ModalFooter
                confirmationLabel={t('case.documents.modals.combine.title')}
                onClick={onClickCombine}
                secondary={{
                    onClick: onClose,
                }}
                isLoading={isExecutingPromise}
            />
        </Modal>
    );
};

export default CombineFilesModal;
